import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Helmet } from 'react-helmet';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Helmet>
        <meta name="keywords" content="ANTI-AGING, SMART AGING, TRX, KETTLEBELL, HIIT, MASSOTERAPIA, MASSAGEM, Bambooterapia, correr Lisboa, Corrida Lisboa, Depilação a laser, drenagem linfática, estética facial, exercício laboral, fisioterapia, ginásio olivais, ginásio parque das nações, treino personalizado, triathlon, triatlo, trx lisboa, yoga, yoga para grávidas, yoga suspenso, ácido hialurônico, ginásio, massagem, medicina chinesa, natação, nutrição funcional, osteopata, personal trainer, pilates, run lisbon, treino funcional" />
    </Helmet>
    <App />
  </React.StrictMode>
);
