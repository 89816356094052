import React from "react";

const Events = () => {
  return (
    <div>
      <h1>Welcome to the Events Page!</h1>
      <p>This is the main content of the Events page.</p>
    </div>
  );
};

export default Events;
