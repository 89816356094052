import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Contact from "./Contact";
import NoPage from "./NoPage";
import MyNavbar from "../components/MyNavbar";
import Footer from "../components/Footer";
import Triathlon from "./Triathlon";
import Modalidades from "./Modalidades";
import About from "./About";
import Terapias from "./Terapias";
import Events from "./Events";
import Clientes from "./Clientes";
import Mapa from "./Mapa";
import Treinos from "./Treinos";

import "../css/Layout.css";

const Layout = () => {
  return (
    <div>
      <MyNavbar />
      <main className="content">
        <Routes>
          <Route index element={<Home />} />
          <Route path="/About" element={<About />} />
          <Route path="/Modalidades" element={<Modalidades />} />
          <Route path="/Triathlon" element={<Triathlon />} />
          <Route path="/Terapias" element={<Terapias />} />
          <Route path="/Clientes empresariais" element={<Clientes />} />
          <Route path="/Mapa aulas" element={<Mapa />} />
          <Route path="/Treinos" element={<Treinos />} />
          <Route path="/Events" element={<Events />} />
          <Route path="/Contacts" element={<Contact />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </main>

      <Footer />
    </div>
  );
};

export default Layout;
