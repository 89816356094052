import React from "react";
import { FaFacebookF, FaInstagram, FaYoutube } from "react-icons/fa";
import parceiro_germano from "../assets/Footer/parceiro_germano.jpg";
import protocolos_diet from "../assets/Footer/protocolos_diet.png";
import protocolos_ivi from "../assets/Footer/protocolos_ivi.jpg";
import protocolos_pronatural from "../assets/Footer/protocolos_pronatural.jpg";
import protocolos_LP from "../assets/Footer/protocolos_LP.png";
import protocolos_sams from "../assets/Footer/protocolos_sams.png";
import "../css/Footer.css";

const Footer = () => {
  return (
    <footer className="bg-white text-dark  border-top border-2 pt-4">
      <div className="container">
        <div className="row text-center">
          <div className="col-md-4 col-sm-6 text-sm-start text-center">
            <h5 className="fw-bold mb-4 pb-2">Entre em contacto</h5>
            <p>
              Fale connosco! Quer saber mais sobre os nossos serviços? Envie um
              mail para{" "}
              <a href="info@o2-lifecenter.com">info@o2-lifecenter.com</a>
            </p>
            <h5 className="fw-bold mb-4">Siga-nos!</h5>
            <div className="mb-4">
              <a
                className="pe-2 text-black"
                href="https://www.facebook.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF className="social-icon" size={30} />
              </a>
              <a
                className="p-2 text-black"
                href="https://www.instagram.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram className="social-icon" size={32} />
              </a>
              <a
                className="p-2 text-black"
                href="https://www.youtube.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaYoutube className="social-icon" size={36} />
              </a>
            </div>
          </div>
          <div className="parceiros col-md-4 col-sm-6">
            <h5 className="fw-bold mb-4 pb-2">Protocolos</h5>
            <img
              alt="Diet Import"
              className="w-50 img-fluid me-5 mb-4"
              src={protocolos_diet}
            ></img>
            <img
              alt="LPAA"
              className="w-25 img-fluid"
              src={protocolos_LP}
            ></img>
            <img
              alt="IVI"
              className="w-25 img-fluid"
              src={protocolos_ivi}
            ></img>
            <img
              alt="Sams Quadros"
              className="w-50 img-fluid"
              src={protocolos_sams}
            ></img>
            <img
              alt="Pronatural"
              className="w-25 img-fluid"
              src={protocolos_pronatural}
            ></img>
          </div>
          <div className="col-md-4 col-sm-6">
            <h5 className="fw-bold mb-4 pb-2">Parceiros</h5>
            <img
              alt="Germano De Sousa"
              className="w-50 img-fluid mb-5"
              src={parceiro_germano}
            ></img>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-end">
            <p className="mb-4">
              Copyright © {new Date().getFullYear()} LifeCenter. All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
