import React from 'react';
import '../App.css';

const NewsletterSignup = () => {
  return (
    <div id="mc_embed_shell" className="newsletter-container">
      <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
      <style type="text/css">
        {`
          #mc_embed_signup { background: #fff; clear: left; font: 14px Helvetica, Arial, sans-serif; width: 100%; }
          .mc-field-group { margin-bottom: 20px; }
          .asterisk { color: red; }
        `}
      </style>
      <div id="mc_embed_signup">
        <form
          action="https://lifecenter.us7.list-manage.com/subscribe/post?u=36c8af2583884dd3b98605078&amp;id=cb6cba151f&amp;f_id=0013d8e4f0"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <h2>Subscreve a nossa Newsletter</h2>
            <div className="indicates-required">
              <span className="asterisk">*</span> indica obrigatório
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">Email <span className="asterisk">*</span></label>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                placeholder="Insere o Email"
                required
              />
            </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input type="text" name="b_36c8af2583884dd3b98605078_cb6cba151f" tabIndex="-1" value="" />
            </div>
            <div className="text-center mt-3 button-inscrever" >
              <input
                type="submit"
                value="SUBSCREVER"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="contactos_botão" // Usar a mesma classe de estilo
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewsletterSignup;
