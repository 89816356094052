import React, { useState } from "react";
import terapias_osteopatia from "../assets/Terapias/terapias_osteopatia.png";
import terapias_nutricao from "../assets/Terapias/terapias_nutricao.png";
import terapias_fisio from "../assets/Terapias/terapias_fisio.png";
import terapias_massagem from "../assets/Terapias/terapias_massagem.png";
import terapias_estetica from "../assets/Terapias/terapias_estetica.png";
import terapias_presso from "../assets/Terapias/terapias_presso.png";
import terapias_drenagem from "../assets/Terapias/terapias_drenagem.png";
import terapias_lipo from "../assets/Terapias/terapias_lipo.png";
import terapias_relax from "../assets/Terapias/terapias_relax.png";

import "../css/Terapias.css";
import { LuCalendarDays, LuClock } from "react-icons/lu";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

const TerapiasComponent = () => {
  const terapiasData = [
    {
      id: 1,
      image: terapias_osteopatia,
      title: "Osteopatia",
      text: (
        <p>
          <b>Osteopatia</b> é uma terapia que utiliza a manipulação do corpo
          (esqueleto, músculos, ligamento e tecidos) para reduzir o desconforto,
          melhorar o movimento articular e restaurar o bem-estar.
        </p>
      ),
      time: "40 min - 60 min",
      freq: "Seg / Qua / Sex",
    },
    {
      id: 2,
      image: terapias_nutricao,
      title: "Nutrição Funcional",
      text: (
        <p>
          A <b>Nutrição Funcional</b> parte do princípio que cada um de nós tem
          necessidades diferentes com expressão genética, reações alimentares e
          ritmos biológicos únicos.
        </p>
      ),
      time: "30 min - 60 min",
      freq: "Data sob consulta",
    },
    {
      id: 3,
      image: terapias_fisio,
      title: "Fisioterapia/Terapia de Bowen",
      text: (
        <p>
          <stron>Fisioterapia</stron> é o serviço a indivíduos e populações de
          forma a desenvolver, manter e restaurar o máximo movimento e
          capacidade funcional ao longo da vida.
        </p>
      ),
      time: "60 min",
      freq: "Quarta-feira",
    },
    {
      id: 4,
      image: terapias_massagem,
      title: "Massagem de Recuperação",
      text: (
        <p>
          A <b>Massagem de Recuperação</b> implica a utilização de um conjunto
          de técnicas específicas orientadas para aumentar a flexibilidade
          muscular e tonificar os tecidos, dando especial atenção a determinadas
          partes de corpo.
        </p>
      ),
      time: "30 min - 60 min",
      freq: "Quarta-feira",
    },
    {
      id: 5,
      image: terapias_estetica,
      title: "Medicina Estética Facial",
      text: (
        <p>
          A <b>Medicina Estética Facial</b>, engloba um conjunto de tratamentos
          estéticos e reconstrutivos não invasivos, que tem como objetivo
          corrigir e/ou melhorar a vertente estética facial
        </p>
      ),
      time: "60 min",
      freq: "Dias a agendar",
    },
    {
      id: 6,
      image: terapias_presso,
      title: "Pressoterapia",
      text: (
        <p>
          A <b>Pressoterapia</b> consiste em calçar um par de botas de
          compressão com 8 câmaras de ar.
        </p>
      ),
      time: "30 min",
      freq: "Dias a agendar",
    },
    {
      id: 7,
      image: terapias_drenagem,
      title: "Drenagem Linfática Manual",
      text: (
        <p>
          A <b>Drenagem Linfática Manual</b> é uma técnica de massagem cujos
          movimentos aplicados são constantes, ritmados e precisos, de leve
          pressão, capazes de estimular o sistema linfático, acelerando o
          processo de transporte da linfa para os gânglios linfáticos.
        </p>
      ),
      time: "60 min",
      freq: "Dias a agendar",
    },
    {
      id: 8,
      image: terapias_lipo,
      title: "Lipomodelagem",
      text: (
        <p>
          A <b>Lipomodelagem</b> é uma massagem modeladora de alto impacto com
          resultados comprovados.
        </p>
      ),
      time: "60 min",
      freq: "Dias a agendar",
    },
    {
      id: 9,
      image: terapias_relax,
      title: "Massagem de relaxamento",
      text: (
        <p>
          A <b>Massagem de Relaxamento</b> proporciona o alívio das tensões
          musculares e do stress
        </p>
      ),
      time: "60 min",
      freq: "Terça - Feira (14h às 20h)",
    },
  ];

  const [openIds, setOpenIds] = useState([]);

  const handleDivClick = (id) => {
    if (openIds.includes(id)) {
      setOpenIds(openIds.filter((openId) => openId !== id));
    } else {
      setOpenIds([...openIds, id]);
    }
  };

  return (
    <div className="container" style={{ textAlign: "-webkit-center" }}>
      <div className="row">
        {terapiasData.map((terapia) => (
          <div
            className="col-xl-4 col-lg-6 col-xs-12 mb-5 pb-4"
            key={terapia.id}
          >
            <div className="terapia-item">
              <div className="terapias-container">
                <img
                  style={{ width: "360px" }}
                  src={terapia.image}
                  alt={terapia.title}
                ></img>
                <div className="terapias-overlay">
                  <h4 className="text-uppercase">{terapia.title}</h4>
                </div>
              </div>
              <div
                className="terapias-clicable p-3 pb-0 text-justify"
                style={{ backgroundColor: "rgb(237 213 169 / 75%)" }}
                onClick={() => handleDivClick(terapia.id)}
              >
                {terapia.text}
                <div className="terapias-icon">
                  {openIds.includes(terapia.id) ? (
                    <BsChevronUp size={30} />
                  ) : (
                    <BsChevronDown size={30} />
                  )}
                </div>
              </div>
              {openIds.includes(terapia.id) && (
                <div className="animated-div d-flex justify-content-evenly text-center">
                  <div>
                    <LuClock className="icon" />
                    <p className="mb-0 fs-6">{terapia.time}</p>
                  </div>
                  <div>
                    <LuCalendarDays className="icon" />
                    <p className="mb-0 fs-6">{terapia.freq}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TerapiasComponent;
