import React from "react";
import bruno_brito from "../assets/Triathlon/bruno_brito.png";
import diogo_custodio from "../assets/Triathlon/diogo_custodio.png";
import triathlon_ironman from "../assets/Triathlon/triathlon_ironman.png";
import triathlon_maratona from "../assets/Triathlon/triathlon_maratona.png";
import triathlon_olimpico from "../assets/Triathlon/triathlon_olimpico.png";
import triathlon_sprint from "../assets/Triathlon/triathlon_sprint.png";

const Triathlon = () => {
  return (
    <div>
      <div className="container">
        <h2 className="texto-titulo mt-5 mb-4 ">TRIATHLON COACHING</h2>
        <h4 className="text-center my-5">
          SUPERE OS SEUS LIMITES E ALCANCE O SEU POTENCIAL MÁXIMO COMO ATLETA DE
          TRIATHLON COM A NOSSA ORIENTAÇÃO ESPECIALIZADA E PERSONALIZADA.
        </h4>
      </div>
      <div style={{ backgroundColor: "#6b7bb4" }}>
        <div className="container">
          <div className="row py-5">
            <div className="col-lg-6 col-12">
              <h2 className="text-center text-white mb-4">O NOSSO PLANO</h2>
              <p className="text-justify px-5 mb-5">
                O <strong>triathlon coaching</strong> é um processo em que um
                treinador especializado trabalha com um atleta para desenvolver
                um plano de treinamento personalizado, acompanhando o progresso
                e fazendo ajustes conforme necessário, além de fornecer
                aconselhamento em áreas relevantes como nutrição e planeamento
                de competições. O objetivo é ajudar o atleta a atingir o seu
                máximo potencial e desempenho na modalidade.
              </p>
            </div>
            <div className="col-lg-6 col-12 ">
              <h2 className="text-center text-white mb-4">COACHES</h2>
              <div className="row">
                <div className="col text-center">
                  <div className="redondas">
                    <img
                      className="rounded-circle"
                      src={bruno_brito}
                      alt="Bruno Brito"
                    />
                  </div>
                  <h6 className="text-white mt-3">BRUNO BRITO</h6>
                </div>
                <div className="col text-center">
                  <div className="redondas">
                    <img
                      className="rounded-circle"
                      src={diogo_custodio}
                      alt="Diogo Custódio"
                    />
                  </div>
                  <h6 className="text-white mt-3">DIOGO CUSTÓDIO</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <h2 className="text-center mt-5 mb-4 ">SELECIONE O SEU EVENTO</h2>

        <h2 className="text-center text-white mb-4">COACHES</h2>
        <div className="row">
          <div className="col-lg-3 col-sm-6 text-center mb-5">
            <div className="redondas">
              <img
                className="rounded-circle"
                src={triathlon_maratona}
                alt="PLANO DE TREINO MARATONA"
              />
            </div>
            <h6 className="mt-3 mb-1">PLANO DE TREINO MARATONA</h6>
            <p className="mb-1">36 semanas</p>
            <p className="mb-1">
              Semanal: <strong> 4 corridas, 1 força</strong>
            </p>
            <p>
              Intensidade semanal:<strong> + de 5 horas de treino</strong>
            </p>
          </div>
          <div className="col-lg-3 col-sm-6 text-center mb-5">
            <div className="redondas">
              <img
                className="rounded-circle"
                src={triathlon_sprint}
                alt="SUPER SPRINT TRIATHLON"
              />
            </div>
            <h6 className="mt-3 mb-1">SUPER SPRINT TRIATHLON</h6>
            <p className="mb-1">12 semanas</p>
            <p className="mb-1">
              Semanal: <strong> 2 corridas, 2 natação, 2 bike</strong>
            </p>
            <p>
              Intensidade semanal:<strong> + de 4.5 horas de treino</strong>
            </p>
          </div>
          <div className="col-lg-3 col-sm-6 text-center mb-5">
            <div className="redondas">
              <img
                className="rounded-circle"
                src={triathlon_olimpico}
                alt="TRIATHLON OLÍMPICO"
              />
            </div>
            <h6 className="mt-3 mb-1">TRIATHLON OLÍMPICO</h6>
            <p className="mb-1">20 semanas</p>
            <p className="mb-1">
              Semanal: <strong> 3 corridas, 3 natação, 3 bike</strong>
            </p>
            <p>
              Intensidade semanal:<strong> + de  10 horas de treino</strong>
            </p>
          </div>
          <div className="col-lg-3 col-sm-6 text-center mb-5">
            <div className="redondas">
              <img
                className="rounded-circle"
                src={triathlon_ironman}
                alt="IRONMAN 70.3 TRIATHLON"
              />
            </div>
            <h6 className="mt-3 mb-1">IRONMAN 70.3 TRIATHLON</h6>
            <p className="mb-1">28 semanas</p>
            <p className="mb-1">
              Semanal: <strong> 3 corridas, 3 natação, 3 bike</strong>
            </p>
            <p>
              Intensidade semanal:<strong> + 15 horas de treino</strong>
            </p>
          </div>
        </div>
        <h3 className="text-center mb-4">
          POR QUE O TRIATHLON COACHING É IDEAL PARA SI
        </h3>
        <p className="text-justify fs-5 px-5 mx-5 mb-5">
          Aderir ao nosso plano de <strong>triathlon coaching</strong> pode
          ajudar o atleta a atingir os seus objetivos na modalidade, maximizar o
          seu potencial e desempenho, bem como oferecer aconselhamento e
          orientação em várias áreas relevantes para a prática desportiva.
        </p>
        <div className="text-center mb-5">
          <h3 className="mb-4">QUER SABER MAIS?</h3>
          <a href="/contacts" className="btn-contactos">
            CONTACTE-NOS
          </a>
        </div>
      </div>
    </div>
  );
};

export default Triathlon;
