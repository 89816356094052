import React from "react";
import { ImPhone } from "react-icons/im";
import { IoIosMail } from "react-icons/io";
import { MdLocationOn } from "react-icons/md";
import "../css/Contact.css";
import NewsletterSignup from './NewsletterSignup'; // Importe o novo componente

const Contact = () => {
  return (
    <div className="container">
      <h2 className="texto-titulo text-center my-5 pb-4">CONTACTOS</h2>

      <div className="row mb-5">
        <div className="col-12 col-lg-8">
          <div className="google_maps mb-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3111.0264083944758!2d-9.1054915!3d38.76309829999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd19322966bbf75f%3A0x30cfdeb2aee23f11!2sLife%20Center!5e0!3m2!1spt-PT!2spt!4v1688063091967!5m2!1spt-PT!2spt"
              width="800"
              height="450"
              title="Maps"
              style={{ border: "0" }}
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="col-12 col-lg-3 ms-5">
          <h3 className="mb-5 text-center">LIFECENTER</h3>
          <div className="d-flex align-items-center mb-5">
            <MdLocationOn
              size={50}
              className="p-1 me-1"
              style={{ color: "#6b7bb4" }}
            />
            <p className="mb-0">
              Avenida de Pádua nº 10 L <br />
              1800-297 Lisboa
            </p>
          </div>
          <div className="d-flex align-items-center mb-5">
            <ImPhone
              size={50}
              className="p-1 me-1"
              style={{ color: "#6b7bb4" }}
            />
            <p className="mb-0">
              <a className="contactos_textos" href="tel:+351218531900">
                Tel.: 218 531 900
              </a>{" "}
              <br />
              <a className="contactos_textos" href="tel:+351962281179">
                Tlm: 962 281 179
              </a>
            </p>
          </div>
          <div className="d-flex align-items-center mb-5">
            <IoIosMail
              size={50}
              className="p-1 me-1"
              style={{ color: "#6b7bb4" }}
            />
            <a
              href="mailto:info@o2-lifecenter.com"
              className="contactos_textos mb-0"
            >
              info@o2-lifecenter.com
            </a>
          </div>
          <div className="text-center mb-4">
            <a href="" className="contactos_botão">
              CONTACTE-NOS
            </a>
          </div>
          <NewsletterSignup />
        </div>
      </div>
    </div>
  );
};

export default Contact;
