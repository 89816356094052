import React from "react";
import ClientesComponent from "../components/ClientesComponent";
import icon1 from "../assets/Clientes/icon1.png";
import icon2 from "../assets/Clientes/icon2.png";
import icon3 from "../assets/Clientes/icon3.png";
import icon4 from "../assets/Clientes/icon4.png";

const Clientes = () => {
  return (
    <div>
      <div className="container ps-4">
        <h4 className="fw-bold text-black mt-5 pb-4">
          Acreditamos que colaboradores mais felizes e saudáveis, se tornam nos
          mais produtivos!
        </h4>
        <p className="mb-2">
          No âmbito do apoio corporativo o Life Center, segue os seguintes
          passos:
        </p>
        <ul>
          <li>Levantamento das necessidades das empresas</li>
          <li>Avaliação do perfil/perfis dos colaboradores</li>
          <li>Criação de planos de saúde e exercício ajustados</li>
          <li>Implementação dos planos</li>
        </ul>
        <p>
          Queremos fazer sempre mais e melhor, colocando sempre a satisfação dos
          nossos clientes/parceiros como metas a atingir, adaptando e ajustando
          os nossos serviços.
        </p>
      </div>
      <div className="container">
        <h2 className="texto-titulo my-5 pb-4">PROPOSTAS DE SERVIÇOS</h2>
      </div>
      <ClientesComponent />
      <div className="container ">
        <h2 className="texto-titulo my-5 pb-4">CLIENTES EMPRESARIAIS</h2>

        <div className="d-flex row">
          <div className="col-xxl-3 col-md-6 col-12 mb-5 text-center align-self-center">
            <img
              style={{ width: "250px" }}
              src={icon1}
              alt="Icones Empresariais"
            />
          </div>
          <div className="col-xxl-3 col-md-6 col-12 mb-5 text-center align-self-center">
            <img
              style={{ width: "250px" }}
              src={icon2}
              alt="Icones Empresariais"
            />
          </div>
          <div className="col-xxl-3 col-md-6 col-12 mb-5 text-center align-self-center">
            <img
              style={{ width: "250px" }}
              src={icon3}
              alt="Icones Empresariais"
            />
          </div>
          <div className="col-xxl-3 col-md-6 col-12 mb-5 text-center align-self-center">
            <img
              style={{ width: "250px" }}
              src={icon4}
              alt="Icones Empresariais"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clientes;
