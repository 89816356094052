import React from "react";
import ModalidadesImages from "../components/ModalidadesImages";

const Modalidades = () => {
  return (
    <div>
      <div className="container">
        <h2 className="texto-titulo my-5 pb-4">MODALIDADES</h2>
        <ModalidadesImages />
      </div>
      <div
        className="row text-center"
        style={{ backgroundColor: "rgb(243 228 197)" }}
      >
        <div className="col-lg-3 col-md-6 col-sm-12 ">
          <h6 className="text-uppercase my-4">SALA DE EXERCÍCIOS</h6>
          <p className="text-start px-5">
            Utilização livre da Sala de Exercício, mediante marcação prévia
          </p>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 ">
          <h6 className="text-uppercase my-4">PACK SMALL GROUP</h6>
          <p className="text-start px-5">
            <ul>
              <li>
                Acesso às aulas de grupo, realizadas apenas no horário da hora
                de almoço.
              </li>
              <li>Aulas em grupo de 6 pessoas.</li>
              <li>
                Inclui o acesso à sala de exercício, mediante marcação prévia.
              </li>
            </ul>
          </p>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12 ">
          <h6 className="text-uppercase my-4">AULAS A VULSO</h6>
          <p className="text-start px-5">
            Acesso a qualquer aula de grupo à escolha.
          </p>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-12">
          <h6 className="text-uppercase my-4">PACK TREINO PERSONALIZADO</h6>
          <p className="text-start px-5">
            O Pack inclui 8 aulas de 30′ ou 60′ minutos, acompanhadas sempre por
            profissionais creditados.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Modalidades;
