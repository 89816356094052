import React, { useState } from "react";
import AnaGuerra from "../assets/Equipa/Ana Guerra.jpg"; 
import BrunoBrito from "../assets/Equipa/Bruno Brito.jpg"; 
import DrErivanMontilla from "../assets/Equipa/Dr. Erivan Montilla.jpg"; 
import JoDecahmp from "../assets/Equipa/Jo Dechamp.jpg"; 
import JoanaLima from "../assets/Equipa/Joana Lima.jpg"; 
import JoanaMateus from "../assets/Equipa/Joana Mateus.jpg"; 
import RicardoFreitas from "../assets/Equipa/Ricardo Freitas.jpg"; 
import SimoneToller from "../assets/Equipa/Simone Toller.jpg"; 


// List of team members with a mix of imported and path-referenced images
const teamMembers = [
  { photo: BrunoBrito, name: "Bruno Brito", description: "MENTOR, FUNDADOR, PT", info: "" },
  { photo: AnaGuerra, name: "Ana Guerra", description: "OSTEOPATA, ENFERMEIRA", info: "Sou a Ana Guerra, Licenciada em Enfermagem e Diplomada em Osteopatia.\r\nEspecialista em Saúde Mental e Psiquiatria, Pós-graduada em Enfermagem\r\ndo Desporto, Psicologia do Desporto e Treino Terapêutico. Docente na\r\nUniversidade Católica Portuguesa.\r\n\r\nA minha experiência profissional tem-se desenvolvido ao longo de 17\r\nanos, quer na área de enfermaria hospitalar, quer nos cuidados ao\r\npaciente/atleta.\r\n\r\nCom uma ampla gama de ferramentas, procuro trazer uma abordagem\r\nholística, centrada na saúde do paciente, com vista a um completo\r\nbem-estar.\r\n\r\nA Osteopatia enquadra-se dentro das TNC (Terapias Não Convencionais) e\r\ntem como objetivo diagnosticar, tratar e prevenir distúrbios\r\nneuro-músculo-esqueléticos e outras alterações relacionadas, por meio de\r\num raciocínio próprio e utilizando uma variedade de técnicas manuais\r\npara melhorar funções fisiológicas e restaurar o equilíbrio do corpo.\r\n\r\nÉ indicada para diversas patologias e todas as idades.\r\n" },
  { photo: DrErivanMontilla, name: "Dr. Erivan Montilla", description: "MEDICINA ESTÉTICA", info: "" },
  { photo: JoDecahmp, name: "Jo Decahmp", description: "ESTETICISTA, MASSOTERAPEUTA", info: "Sou Jo Dechamp, esteticista e massoterapeuta formada em 2005 pela\r\nfaculdade Martinus na cidade de Curitiba, Paraná, Brasilz\r\nMe faz muito feliz poder ajudar as pessoas a se sentirem melhor,\r\nesteticamente ou fisicamente.\r\nNa área de beleza temos protocolos faciais e corporais como limpeza de\r\npele, tratamentos para rejuvenescer, Peeling clareiador, HIFU, anti acne\r\ne corporais para reduzir gorduras localizadas, celulite, estrias,\r\ninchaço, dores.\r\nEm Lisboa estudei para trabalhar em Spa, então também ofereço terapias\r\nde relaxamento, esfoliação e massagens." },
  { photo: JoanaLima, name: "Joana Lima", description: "NUTRICIONISTA, ENGª ALIMENTAR", info: "" },
  { photo: JoanaMateus, name: "Joana Mateus", description: "FISIOLOLGISTA, PT, RESPONSAVEL COMERCIAL E GESTORA", info: "Sou a Joana Mateus, licenciada em Educação Física e Desporto, e estou\r\natualmente a completar o meu mestrado em Gestão de Desporto. No Life\r\nCenter, comprometo-me a criar estratégias de treino que ajudem os\r\nclientes a superar limitações físicas e a alcançarem os seus objetivos,\r\npromovendo um estado de bem-estar duradouro." },
  { photo: RicardoFreitas, name: "Ricardo freitas", description: "PT", info: "Sou o Ricardo de Freitas, licenciado em desporto condição física e\r\nsaúde, tenho formação de treino corretivo e sou fisiologista do\r\nexercício no LifeCenter.\r\nA minha abordagem passa por encontrar estratégias que permitam a pessoa\r\ntreinar fora da dor e de uma forma mais íntegra e funcional." },
  { photo: SimoneToller, name: "Simone Toller", description: "FISIOTERAPEUTA", info: "" },
];

const Equipa = () => {
  const [selectedMember, setSelectedMember] = useState(null);

  const openModal = (member) => {
    setSelectedMember(member);
  };

  const closeModal = () => {
    setSelectedMember(null);
  };

  return (
    <div className="fundo text-center container-fluid pb-4 mb-4">
      <h2 className="text-white py-5">EQUIPA</h2>
      <div className="row mx-md-5 px-md-5">
        {teamMembers.map((member, index) => (
          <div className="col-lg-3 col-md-4 col-sm-4 col-4" key={index}>
            <div
              className="text-center"
              style={{ cursor: "pointer" }}
              onClick={() => openModal(member)}
            >
              <img className="photo" src={member.photo} alt={member.name} />
              <h3 className="name mt-2 mb-0 text-white fs-4">{member.name}</h3>
              <p className="role text-black fs-6">{member.description}</p>
            </div>
          </div>
        ))}
      </div>
      {selectedMember && (
        <div className="modal show" style={{ display: "block" }}>
          <div className="modal-dialog modal-dialog-centered modal-xl"> {/* Use modal-xl for larger modals */}
            <div className="modal-content">
              <button className="modal-close position-absolute top-0 end-0 p-3" onClick={closeModal}>
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                  <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
                </svg>
              </button>
              <div className="modal-body">
                <div className="row align-items-center">
                  <div className="col-lg-4 text-center">
                    <img
                      className="img-fluid rounded-circle"
                      src={selectedMember.photo}
                      alt={selectedMember.name}
                      style={{ maxWidth: "300px", height: "auto" }}
                    />
                  </div>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-12 text-center">
                        <h3 className="modal-name mt-2 mb-0 fs-1 text-uppercase">
                          {selectedMember.name}
                        </h3>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="modal-role mt-2 text-black fs-4 text-center">
                          {selectedMember.description}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="text-black fs-4" style={{ padding: "20px" }}> {/* Added padding */}
                          {selectedMember.info}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Equipa;
