import React, { useState } from "react";
import clientes_laboral from "../assets/Clientes/clientes_laboral.png";
import clientes_chair from "../assets/Clientes/clientes_chair.png";
import clientes_nucleo from "../assets/Clientes/clientes_nucleo.png";
import clientes_desafios from "../assets/Clientes/clientes_desafios.png";
import clientes_workshop from "../assets/Clientes/clientes_workshop.png";
import clientes_social from "../assets/Clientes/clientes_social.png";

//import "../css/Clientes.css";

const ClientesComponent = () => {
  const clientesData = [
    {
      id: 1,
      image: clientes_laboral,
      text: (
        <p>
          É uma série de exercícios físicos realizados no ambiente de trabalho,
          durante o expediente, com o objetivo de melhorar a saúde e evitar
          lesões. Exercício de baixa intensidade e ocorre num curto período de
          tempo.
        </p>
      ),
    },
    {
      id: 2,
      image: clientes_chair,
      text: (
        <p>
          É uma massagem rápida, com duração de 10 a 15 minutos, realizada numa
          cadeira especialmente projetada.
        </p>
      ),
    },
    {
      id: 3,
      image: clientes_nucleo,
      text: (
        <p>
          Criação de um serviço de corrida ou de um núcleo de corrida, no âmbito
          de um projecto de comunicação associado ao exercício e bem estar.
        </p>
      ),
    },
    {
      id: 4,
      image: clientes_desafios,
      text: (
        <p>
          Criação de desafios de saúde e bem estar com conteúdos de saúde e
          exercício que poderão fazer parte do plano de comunicação interno.
        </p>
      ),
    },
    {
      id: 5,
      image: clientes_workshop,
      text: (
        <p>
          Workshop ou ações de formação para todos os colaboradores:
          <ul>
            <li>Dicas e sugestões de exercícios</li>
            <li>Dicas de alimentos / receitas</li>
            <li>Aconselhamento alimentar</li>
          </ul>
        </p>
      ),
    },
    {
      id: 6,
      image: clientes_social,
      text: (
        <p>
          Criação de conteúdos de saúde e exercício, semanais, para tv ou rádio.
          De forma, a poder consciencializar e alertar os colaboradores, para
          hábitos mais saudáveis.
        </p>
      ),
    },
  ];
  return (
    <div className="container" style={{ textAlign: "-webkit-center" }}>
      <div className="row">
        {clientesData.map((terapia) => (
          <div
            className="col-lg-4 col-md-6 col-xs-12 mb-5 pb-4"
            key={terapia.id}
          >
            <div className="terapia-item">
              <img
                style={{ width: "360px" }}
                src={terapia.image}
                alt="Imagem"
              ></img>

              <div
                className="text-justify"
                style={{ paddingLeft: "40px", paddingTop: "10px" }}
              >
                {terapia.text}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientesComponent;
