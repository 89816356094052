import React from "react";
import { Carousel } from "react-bootstrap";
import "../css/Treinos.css";

const Treinos = () => {
  return (
    <div className="container">
      <h2 className="texto-titulo mt-5 py-3">TREINOS VIRTUAIS</h2>
      <p>Confira aqui treinos para fazer em casa.</p>

      <Carousel data-bs-theme="dark">
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
        <Carousel.Item className="text-center">
          <iframe
            className="w-100 pb-5 videos"
            src="https://www.youtube.com/embed/fv7Lc0HxAjE"
            title="Tonificação - Glúteos, ABS e Pernas | Nível: Iniciante | Tempo: 2 min. | Lidl Portugal"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Carousel.Item>
      </Carousel>
      <p className="mt-5">Temos outros vídeos na nossa página do YouTube.</p>
      <a
        href="https://www.youtube.com/channel/UCjzPufw8fcyxJUPcaPy4S8g"
        className="text-black fw-bold"
        target="_blank"
        rel="noreferrer"
      >
        Clique aqui
      </a>
    </div>
  );
};

export default Treinos;
