import React from "react";
import "../css/About.css";
import About_1 from "../assets/About/About_1.png";
import About_2 from "../assets/About/About_2.png";
import About_3 from "../assets/About/About_3.png";
import About_4 from "../assets/About/About_4.png";
import Equipa from "../components/Equipa.js";

const About = () => {
  return (
    <div>
      <div className="container mt-5">
        <div className="row gx-5">
          <div
            className="col-md-8 texto-about mb-4"
            style={{ paddingRight: "60px" }}
          >
            <h2 className="titulo-about mb-4 pb-3">SOBRE NÓS</h2>
            <p>
              O <b>Life Center</b> nasce a 5 de Janeiro de 2015 determinado em fazer a diferença nas áreas da saúde e exercicio.
            </p>
            <p>
            Dotado de profissionais motivados e conscientes da importância da união entre o corpo e a mente este projeto arranca desde o 1º dia com{" "}
              <b>
                a missão de fazer mais e melhor, pela saúde e pelo movimento.
              </b>
            </p>
            <p>
            Os nossos <b>profissionais</b> pretendem, sempre, ser orientadores e guias de um estilo de vida  em prol da saúde e prevenção de patologias. 
            </p>
            <p>
              É isto que nos motiva e é com estas premissas em mente que construímos equipa.
            </p>
            <p>
            Por estes motivos e por tantos outros que preferimos deixar para quando nos visitar, sabemos que <b>somos mais que um Ginásio somos um Life Center</b>, um lugar onde se deve sentir entre amigos(as), onde os seus pedidos são atendidos e o cuidar de si é total.
            </p>
          </div>

          <div className="col align-self-center mb-4">
            <h6 className="titulo-about text-center mb-4">MISSÃO</h6>
            <p className="texto-about">
              A missão do Life Center é a promoção da saúde através do
              exercício. Trabalhamos em prol da prevenção das patologias, tendo
              sempre por base os pilares da saúde, nutrição e exercício.
              Acreditamos que exercício físico é medicina e que ambos devem
              coexistir por forma a assegurar o bem estar físico, emocional e
              psicológico de cada pessoa que nos procura.
            </p>
            <p className="texto-about">
              Venha visitar-nos e perceber a diferença
            </p>
            <div className="d-flex justify-content-evenly">
              <img className="about-imagem" alt="icon" src={About_1}></img>
              <img className="about-imagem" alt="icon" src={About_2}></img>
              <img className="about-imagem" alt="icon" src={About_3}></img>
              <img className="about-imagem" alt="icon" src={About_4}></img>
            </div>
          </div>
        </div>
      </div>
      <Equipa />
    </div>
  );
};

export default About;
