import React from "react";
import About_1 from "../assets/About/About_1.png";
import About_2 from "../assets/About/About_2.png";
import About_3 from "../assets/About/About_3.png";
import About_4 from "../assets/About/About_4.png";
import "../css/Home.css";

const Home = () => {
  return (
    <div className="container">
      <h2 className="text-center texto-titulo my-5 ">O QUE FAZEMOS?</h2>
      <div className="row gx-5">
        <div className="col-xxl-3 col-md-6 col-12 text-center px-2 py-4">
          <img className="w-50 pb-4" alt="icon" src={About_1}></img>
          <p className="fw-bold">
            EXERCÍCIO FÍSICO/AULAS DE GRUPO/TREINO PERSONALIZADO
          </p>
          <p className="text-justify px-3">
            A importância da prática de exercício físico faz-nos apostar num
            serviço diferenciado e de proximidade. Tratamos cada caso com a
            singularidade que merece com o rigor e exigência. Apostamos em
            profissionais que pela sua experiência e competência sejam
            Treinadores de Exercício, Bem-Estar e Felicidade.
          </p>
        </div>
        <div className="col-xxl-3 col-md-6 col-12 text-center px-2 py-4">
          <img className="w-50 pb-4" alt="icon" src={About_2}></img>
          <p className="fw-bold">TRIATHLON COACHING</p>
          <p className="text-justify px-3">
          O coaching de triatlo é um processo em que um treinador especializado
          nesta modalidade trabalha com um atleta para ajudá-lo a atingir os seus objetivos
           na prática desportiva. O coaching de triatlo envolve o desenvolvimento de 
           um plano de treino personalizado, adaptado às habilidades e metas específicas 
           de cada atleta, bem como o acompanhamento regular do progresso do atleta e a 
           implementação de ajustes no treino, se necessário.
          </p>
        </div>
        <div className="col-xxl-3 col-md-6 col-12 text-center px-2 py-4">
          <img className="w-50 pb-4" alt="icon" src={About_3}></img>
          <p className="fw-bold">CLÍNICA / TERAPIAS COMPLEMENTARES</p>
          <p className="text-justify px-3">
          A saúde representa um pilar fundamental na construção do Life Center. 
          Desde as análises clínicas com o Centro de Medicina Laboratorial Germano
           de Sousa passando pela Osteopatia, Nutrição Funcional, Fisioterapia, 
           Massagem Desportiva, Massoterapia, Tratamentos de Rosto e Corpo, Fisoterapia
            na Grávida, Medicina Estética Facial o que queremos é que saiba que connosco
             a nossa maior prioridade é a sua saúde, bem-estar e felicidade.
          </p>
        </div>
        <div className="col-xxl-3 col-md-6 col-12 text-center px-2 py-4">
          <img className="w-50 pb-4" alt="icon" src={About_4}></img>
          <p className="fw-bold">CLIENTES EMPRESARIAIS</p>
          <p className="text-justify px-3">
            Colaboradores saudáveis traduzem empresas Felizes e é desta forma
            que queremos apostar num serviço que cumpra a análise, diagnóstico,
            avaliação, rastreio das necessidades com vista à implementação.
            Queremos fazer mais e melhor pela sua empresa.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
