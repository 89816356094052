import React from "react";

const Mapa = () => {
  return (
    <div>
      <h1>Welcome to the Mapa Page!</h1>
      <p>This is the main content of the Mapa page.</p>
    </div>
  );
};

export default Mapa;
