import React, { useEffect, useState } from "react";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/logo.png";
import "../css/Navbar.css";

function MyNavbar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollOffset = 120; // Adjust this value as needed
      if (window.scrollY > scrollOffset) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Navbar
      id="navbar"
      expand="lg"
      className={`navbar-dark ${isScrolled ? "scrolled" : ""}`}
    >
      <a href="/">
        <img className="logo" alt="Logo" src={logo}></img>
      </a>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav>
          <Nav.Link href="/About" className="titulo">
            Sobre nós
          </Nav.Link>
          <div className="dropdown titulo-dropdown">
            <span className="titulo">Serviços</span>
            <div className="dropdown-content">
              <Nav.Link className="titulo mx-0" href="/Modalidades">
                Modalidades
              </Nav.Link>
              <Nav.Link className="titulo mx-0" href="/Triathlon">
                Triathlon coaching
              </Nav.Link>
              <Nav.Link className="titulo mx-0" href="/Terapias">
                Terapias
              </Nav.Link>
              <Nav.Link className="titulo mx-0" href="/Clientes empresariais">
                Clientes Empresariais
              </Nav.Link>
            </div>
          </div>
         {/* <div className="dropdown titulo-dropdown">
            <span className="titulo">Aulas</span>
            <div className="dropdown-content">
              <Nav.Link className="titulo mx-0" href="/Mapa aulas">
                Mapa de aulas e Marcações
              </Nav.Link>
              <Nav.Link className="titulo mx-0" href="/Treinos">
                Treinos virtuais
              </Nav.Link>
            </div>
          </div>
          <Nav.Link href="/Events" className="titulo">
            Eventos
  </Nav.Link> */}
          <Nav.Link href="/Contacts" className="titulo">
            Contactos
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default MyNavbar;
