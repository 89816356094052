import React from "react";
import modalidades_trx from "../assets/Modalidades/modalidades_trx.png";
import modalidades_power from "../assets/Modalidades/modalidades_power.jpg";
import modalidades_kettle from "../assets/Modalidades/modalidades_kettlebell.png";
import modalidades_yoga from "../assets/Modalidades/modalidades_yoga.png";
import modalidades_aerial from "../assets/Modalidades/modalidades_aerial.png";
import modalidades_gravidas from "../assets/Modalidades/modalidades_gravidas.png";
import modalidades_pilates from "../assets/Modalidades/modalidades_pilates.png";
import modalidades_kempo from "../assets/Modalidades/modalidades_kempo.png";
import modalidades_personalizado from "../assets/Modalidades/modalidades_personalizado.png";

import { BiSignal3, BiSignal2 } from "react-icons/bi";

import "../css/Modalidades.css";

const Modalidades_images = () => {
  const modalidadesData = [
    {
      id: 1,
      image: modalidades_trx,
      title: "trx",
      type: "Full-body",
      intensity: "Intensidade ELEVADA",
      icon: <BiSignal3 size={40} />,
      date: (
        <a className="text-black" href="/Mapa aulas">
          MAPA DE AULAS
        </a>
      ),
      extra: "",
    },
    {
      id: 2,
      image: modalidades_power,
      title: "power circuit",
      type: "Full-body",
      intensity: "Intensidade ELEVADA",
      icon: <BiSignal3 size={40} />,
      date: (
        <p>
          <a className="text-black" href="/Mapa aulas">
            MAPA DE AULAS
          </a>
        </p>
      ),
      extra: "",
    },
    {
      id: 3,
      image: modalidades_kettle,
      title: "kettlebell",
      type: "Full-body",
      intensity: "Intensidade ELEVADA",
      icon: <BiSignal3 size={40} />,
      date: (
        <p>
          <a className="text-black" href="/Mapa aulas">
            MAPA DE AULAS
          </a>
        </p>
      ),
      extra: "",
    },
    {
      id: 4,
      image: modalidades_yoga,
      title: "yoga",
      type: "Full-body",
      intensity: "Intensidade LEVE-MODERDA",
      icon: <BiSignal2 size={40} />,
      date: (
        <p className="">
          <a className="text-black" href="/Mapa aulas">
            MAPA DE AULAS
          </a>
        </p>
      ),
      extra: "",
    },
    {
      id: 5,
      image: modalidades_aerial,
      title: "aerial yoga",
      type: "Full-body",
      intensity: "Intensidade LEVE-MODERDA",
      icon: <BiSignal2 size={40} />,
      date: (
        <p className="">
          <a className="text-black" href="/Mapa aulas">
            MAPA DE AULAS
          </a>
        </p>
      ),
      extra: "",
    },
    {
      id: 6,
      image: modalidades_gravidas,
      title: "yoga para grávidas",
      type: "Full-body",
      intensity: "Intensidade LEVE-MODERDA",
      icon: <BiSignal2 size={40} />,
      date: (
        <p className="">
          <a className="text-black" href="/Mapa aulas">
            MAPA DE AULAS
          </a>
        </p>
      ),
      extra: "",
    },
    {
      id: 7,
      image: modalidades_pilates,
      title: "pilates/treino funcional",
      type: "Full-body",
      intensity: "Intensidade LEVE-MODERDA",
      icon: <BiSignal2 size={40} />,
      date: (
        <p className="">
          <a className="text-black" href="/Mapa aulas">
            MAPA DE AULAS
          </a>
        </p>
      ),
      extra: "",
    },
    {
      id: 8,
      image: modalidades_kempo,
      title: "shorinji-kempo",
      type: "Treino infanto-juvenil",
      intensity: "Intensidade LEVE-MODERDA",
      icon: <BiSignal2 size={40} />,
      date: (
        <p className="">
          <a className="text-black" href="/Mapa aulas">
            MAPA DE AULAS
          </a>
        </p>
      ),
      extra: "Nova Localização - Aulas realizam-se no Extrenato João XXIII",
    },
    {
      id: 9,
      image: modalidades_personalizado,
      title: "treino personalizado",
      type: "O Treino Personalizado é ajustado e adaptado a cada individuo, com base em um questionário de saúde e avaliação física. Temos ao vosso dispor packs de 8 aulas de 60 e de 30 minutos.",
    },
    // Add more modalidades here...
  ];

  return (
    <div className="container">
      <div className="row">
        {modalidadesData.map((modalidade) => (
          <div className="col-lg-4 col-md-6 col-sm-12" key={modalidade.id}>
            <div className="text-center mb-5 pb-1">
              <div className="image-container">
                <img
                  className="img-fluid"
                  src={modalidade.image}
                  alt={modalidade.title}
                />
              </div>
              <h5 className="mt-4 fs-bold mb-2 text-uppercase">
                {modalidade.title}
              </h5>
              <p className="mb-2 px-4 fs-6">{modalidade.type}</p>
              <div
                style={{ height: "25px" }}
                className="d-flex align-items-end justify-content-center mb-2"
              >
                <p className="mb-0 ml-2" style={{ paddingLeft: "40px" }}>
                  {modalidade.intensity}
                </p>
                {modalidade.icon}
              </div>
              {modalidade.date}
              <p style={{ fontSize: "14px" }} className="fw-bold">
                {modalidade.extra}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Modalidades_images;
