import React from "react";
import TerapiasComponent from "../components/TerapiasComponent";
const Terapias = () => {
  return (
    <div>
      <div className="container">
        <h2 className="texto-titulo my-5 pb-4">TERAPIAS</h2>
        <TerapiasComponent />
      </div>
    </div>
  );
};

export default Terapias;
